import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Icon from '../../styles/atoms/icons';

import PricingPlanStyle from '../../styles/Components/GridComponent/PricingPlanStyle';

const PricingPlan = () => {
  const plans = [
    ['Centralized Customer Comms', 'Universal Search', 'Canned Services'],
    ['Workflow Management', 'Payments', 'Unlimited Quotes and Invoices'],
    ['SMS and Email', 'Shop Reporting', 'Industry-Standard Security'],
    ['Data Migration Service', 'Activity History', 'U.S.-Based Support Team'],
  ];
  return (
    <PricingPlanStyle>
      <Container>
        <div className="plans-wrapper">
          <h2>
            All Shopmonkey plans include awesome tools to help you get organized
            and run your shop
          </h2>
          <Row>
            {plans.map((plan, index) => (
              <Col sm={6} md={3} key={`plan-column-${index + 1}`}>
                <ul>
                  {plan.map((item, i) => (
                    <li key={`plan-item-${i + 1}`}>
                      <Icon id="pricingIcon" isImage />
                      <h3>{item}</h3>
                    </li>
                  ))}
                </ul>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </PricingPlanStyle>
  );
};

export default PricingPlan;
